import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({
  apiKey: '90cd134ae7c7827cb40be656016e67b8',
  enabledReleaseStages: ['production'],
  plugins: [new BugsnagPluginVue()]
})
BugsnagPerformance.start({
  apiKey: '90cd134ae7c7827cb40be656016e67b8',
  enabledReleaseStages: ['production']
})

export default Bugsnag
